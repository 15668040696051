module.exports = [{
      plugin: require('/Users/sacha/Documents/Private/radlikewhoa.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sacha/Documents/Private/radlikewhoa.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100,"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/sacha/Documents/Private/radlikewhoa.github.io/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sacha/Documents/Private/radlikewhoa.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
