// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-article-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-index-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-index-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-projects-index-js": () => import("/Users/sacha/Documents/Private/radlikewhoa.github.io/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

